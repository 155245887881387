import "babel-polyfill"
import React from "react"

import { FaInstagram } from "react-icons/fa"

export default class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleChecked: true,
    }
    this.playVideo = this.playVideo.bind(this)
  }

  // Calling a child method from the parent. See: https://stackoverflow.com/questions/37949981/call-child-method-from-parent
  componentDidMount() {
    this.props.setClickPlay(this.playVideo)
  }

  pauseVideo() {
    this.refs.vidRef.pause()
  }
  playVideo() {
    this.refs.vidRef.play()
  }

  render() {
    return (
      <div
        class="modal"
        id="videoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
        style={{ paddingTop: 50 }}
      >
        <div
          class="modal-dialog"
          role="document"
          style={{ height: 500, width: 500, backgroundColor: "transparent" }}
        >
          <div
            class="modal-content  d-flex align-items-center justify-content-center"
            style={{ height: 500, width: 500, backgroundColor: "transparent" }}
          >
            <div
              class="modal-header"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 10,
                border: 0,
              }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.pauseVideo.bind(this)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/* <div class="modal-subheader d-flex flex-row align-items-center justify-content-center">
                <h4
                  class="text-center"
                  style={{ paddingTop: 8, marginRight: 8 }}
                >
                  Share on Instagram
                </h4>
                <FaInstagram size={30} color="#444444" />
              </div> */}

              {/* <div
                class="modal-content-container d-flex flex-column align-items-center justify-content-center"
                style={{ margin: "auto" }}
              >
                <div class="row row-share d-flex flex-row align-items-center justify-content-start"> */}
              <video
                width="500"
                height="500"
                controls={true}
                key={this.props.src}
                ref="vidRef"
                // poster={posterSrc}
              >
                <source src={this.props.src} type="video/mp4" />
              </video>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
