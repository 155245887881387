import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"

// import AppNav from '../components/app/appNav'
// import Amplify, { API, Auth } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../utils/config"
import { connect } from "react-redux"

import Layout from "../components/layout"
import ReportingMenu from "../components/appReporting/reportingMenu"
import ReportingVideoCreation from "../components/appReporting/reportingVideoCreation"
import ReportingUsers from "../components/appReporting/reportingUsers"
import ReportingSocial from "../components/appReporting/reportingSocial"
import ReportingReviews from "../components/appReporting/reportingReviews"
// import AppModerate from "../components/appModerate/appModerate"
import NavApp from "../components/nav/navApp"
import CampaignHeaderImage from "../components/appReporting/campaignHeaderImage"
import MenuBack from "../components/appReporting/menuBack"
import ReportTitle from "../components/appReporting/reportTitle"

import { Helmet } from "react-helmet"

import parse from "url-parse"

import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

import { getCampaigns } from "../actions/getCampaigns"

// eslint-disable-next-line
import "../css/app.css"

Amplify.configure(amplifyconfig)

const mapStateToProps = ({ campaign, isLoading }) => {
  return { campaign, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getCampaigns: () => dispatch(getCampaigns()),
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [],
      moderationcontent: [],
      sessionhide: "",
      refreshing: false,
      loading: true,
    }
    this.videoDisplayConst = 3
  }

  componentDidMount() {
    console.log(">>>>>>this.props.campaign<<<<<<<")
    console.log(this.props.campaign)
    if (!this.props.campaign) {
      this.props.getCampaigns()
    }
  }

  render() {
    // console.log("((this.props))")
    // console.log(this.props)

    const { campaign, isLoading } = this.props
    const url = parse(window.location.href, true)

    // console.log("campaign - url")
    // console.log(url)

    const pathname = url.pathname
    // console.log("((pathname))")
    // console.log(pathname)
    const templateid = url.query.t
    const campaignDetail = campaign
      ? campaign.find(({ id }) => id === url.query.t)
      : {}

    const campaignDetailTitle =
      campaignDetail && campaignDetail.admin && campaignDetail.admin.title
        ? campaignDetail.admin.title
        : ""
    const campaignDetailPoster =
      campaignDetail && campaignDetail.poster ? campaignDetail.poster : ""
    console.log("campaign - campaign")
    console.log(campaign)
    console.log("campaignDetail - campaign")
    console.log(campaignDetail)

    const menuData = [
      {
        header: "Analytics",
        items: [
          {
            title: "Videos created",
            url: "/app/campaign/videos/",
          },
          {
            title: "Users",
            url: "/app/campaign/users/",
          },
          {
            title: "Social sharing",
            url: "/app/campaign/social/",
            access: [{ templateparam: "admin/managesocial", templatevalue: 1 }],
          },
        ],
      },
      {
        header: "Moderation",
        access: [
          { templateparam: "moderate", templatevalue: "reviewmanual" },
          { templateparam: "moderate", templatevalue: "reviewauto" },
        ],
        items: [
          {
            title: "Dashboard",
            url: "/app/moderation/",
          },
        ],
      },
      {
        header: "Reviews",
        access: [{ templateparam: "admin/managereviews", templatevalue: 1 }],
        items: [
          {
            title: "Manage",
            url: "/app/reviews/",
          },
        ],
      },
    ]
    console.log("[[[[campaignDetail]]]]")
    console.log(campaignDetail)
    return (
      <Layout>
        <div class="flex-grow-1">
          {pathname === "/app/moderation/" ? (
            <NavApp title="Moderation" link={false} />
          ) : (
            <NavApp title="Dashboard" link={true} />
          )}

          <div class="container container-app container-page container-reporting">
            <div class="row pl-3">
              {pathname === "/app/moderation/" ? (
                <MenuBack
                  text={campaignDetailTitle}
                  link={`/app/campaign/videos/?t=${templateid}`}
                />
              ) : (
                <MenuBack text="Campaigns" link="/app/home/" />
              )}
            </div>
            <div class="row">
              {pathname !== "/app/moderation/" ? (
                <div class="col-sm-3">
                  <CampaignHeaderImage
                    templateid={templateid}
                    title={campaignDetailTitle}
                    poster={campaignDetailPoster}
                  />
                  <ReportingMenu
                    templateid={templateid}
                    menudata={menuData}
                    campaignDetail={campaignDetail}
                  />
                </div>
              ) : null}
              <>
                {/* {isLoading ? "true" : "false"} */}
                {pathname === "/app/campaign/videos/" ? (
                  <div class="col-sm-9">
                    <ReportTitle text="Videos created" />
                    <ReportingVideoCreation />
                  </div>
                ) : null}
                {pathname === "/app/campaign/users/" ? (
                  <div class="col-sm-9">
                    <ReportTitle text="Users" />
                    <ReportingUsers />
                  </div>
                ) : null}
                {pathname === "/app/campaign/social/" ? (
                  <div class="col-sm-9">
                    <ReportTitle text="Social sharing" />
                    <ReportingSocial />
                  </div>
                ) : null}
                {pathname === "/app/reviews/" ? (
                  <div class="col-sm-9">
                    <ReportTitle text="Manage reviews" />
                    <ReportingReviews />
                  </div>
                ) : null}
                {pathname === "/app/moderation/" ? (
                  <div class="col-sm-12">
                    <AppModerate />
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// export default App
export default connect(mapStateToProps, mapDispatchToProps)(App)
