import { jsonToCSV } from "react-papaparse"

export function setCollectorStart() {
  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
  }
}
export function setCollectorEnd(contactsArray) {
  return dispatch => {
    downloadCSV(contactsArray)
    dispatch({
      type: "COLLECTOR_REPLACE",
      data: {
        message: "collectorsuccess",
        recipient: "Collector completed",
        contacts: contactsArray,
      },
    })
  }
}

export function downloadCSV(json) {
  const results = jsonToCSV(json)
  console.log("results----")
  console.log(results)
  const csvData = new Blob([results], { type: "text/csv;charset=utf-8;" })
  let csvURL
  if (typeof window !== `undefined`) {
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "download.csv")
    } else {
      csvURL = window.URL.createObjectURL(csvData)
    }

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", "download.csv")
    tempLink.click()
  }
}
