// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../../utils/config"

Amplify.configure(amplifyconfig)

import {
  setCollectorStart,
  setCollectorEnd,
  downloadCSV,
} from "../../actions/setCollector"

import { setUserData } from "../../actions/setUserData"

const buttonRef = React.createRef()
const buttonRef2 = React.createRef()

import parse from "url-parse"

import Loading from "../loading"
import EmailStatus from "../appActivation/emailStatus"

import { MdRemoveCircle, MdFileUpload, MdSend } from "react-icons/md"
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io"

const async = require("async")
const cheerio = require("cheerio")

const mapStateToProps = ({ userdata, contactcollector, isLoading }) => {
  return {
    userdata,
    contactcollector,
    isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  setUserData: user => dispatch(setUserData(user)),
  setCollectorStart: () => dispatch(setCollectorStart()),
  setCollectorEnd: contactsArray => dispatch(setCollectorEnd(contactsArray)),
  downloadCSV: contactsArray => dispatch(downloadCSV(contactsArray)),
})

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.state = {}
  }
  componentDidMount() {
    console.log("<<<<<<<<<this.props.userdata>>>>>>>>>>>>>")
    console.log(this.props.userdata)
    if (!this.props.userdata) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          console.log("<<<<user>>>>")
          console.log(user)
          this.props.setUserData(user)
        })
        .catch(error => {
          console.log("auth - error")
          console.log(error)
        }) //end get Auth token
    }
  }

  parseCheerioHtml(htmlString) {
    // return new Promise((resolve, reject) => {
    // }) //end promise

    const contacts = []
    const $ = cheerio.load(htmlString)

    $("li.b_algo").each((i, el) => {
      const personData = $(el)
        .find("h2 a")
        .text()
        .replace(/,/g, "")
        .replace(/\.\.\./g, "")
        .replace(/\| LinkedIn/g, "")
      let linkedinUrl = $(el)
        .find("cite")
        .text()
        .trim()
      let linkedinDesc = $(el)
        .find("p")
        .text()
        .replace(/,/g, "-")
        .trim()
      let linkedinLocation = $(el)
        .find('strong:contains("Location:")')
        .parent()
        .text()
        .replace(/Location:/g, "")
        .replace(/,/g, "")
        .trim()

      if (linkedinDesc.startsWith("View ")) {
        linkedinDesc = ""
      }

      const splitter = personData.split("-")
      const fullname = splitter[0] ? splitter[0].trim() : ""

      //https://stackoverflow.com/questions/10272773/split-string-on-the-first-white-space-occurrence
      //get everything before first space
      const fname = fullname.substr(0, fullname.indexOf(" ")).trim()
      //get everything after first space
      const lname = fullname.substr(fullname.indexOf(" ") + 1).trim()

      const title = splitter[1] ? splitter[1].trim() : ""
      const company = splitter[2] ? splitter[2].trim() : ""

      const person = {
        fname,
        lname,
        title,
        company,
        linkedinUrl,
        linkedinDesc,
        linkedinLocation,
      }
      // console.log("person")
      // console.log(person)
      contacts.push(person)
    }) //end cheerio each

    return contacts
  }

  handleChange(event) {
    console.log("handleChange -----")
    if (!event.target.files[0]) {
      return
    }
    this.props.setCollectorStart()
    console.log(event.target.files)

    let that = this

    let contactsArray = []

    const fileArray = Array.from(event.target.files)
    async.forEachOf(
      fileArray,
      (file, key, callbacksub) => {
        const reader = new FileReader()
        reader.onload = function(e) {
          // e.target.result should contain the text
          // console.log("e.target.result")
          // console.log(e.target.result)
          const contactsFromFile = that.parseCheerioHtml(e.target.result)
          // console.log("^^^^^contactsFromFile")
          // console.log(contactsFromFile)
          //merge arrays
          contactsArray = [...contactsArray, ...contactsFromFile]
          callbacksub()
        } //end reader.onload
        reader.readAsText(file)
      },
      err => {
        //Final function after foreach
        if (err) {
          console.log(err.message)
          return
        }
        // console.log("----contactsArray")
        // console.log(contactsArray)
        // console.log("-- starting this.downloadCSV")
        this.inputFile.value = ""
        this.props.setCollectorEnd(contactsArray)
        // this.props.downloadCSV(contactsArray)
      } //end final function
    ) //end async.forEachOf
  }

  render() {
    const { userdata, contactcollector, isLoading } = this.props
    // console.log("((((contactcollector))))")
    // console.log(contactcollector)

    return (
      <>
        <div class="col-lg-9">
          <div class="reporting-container">
            <div class="row bg-gray-7 pt-3 rounded-corners-top">
              <div class="col-md-6 form-group">
                <input
                  type="file"
                  id="htmlupload"
                  multiple
                  onChange={this.handleChange}
                  ref={el => (this.inputFile = el)}
                />
              </div>
              <div class="col-md-6 form-group"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3" style={{ top: -5 }}>
          <div class="border-1-gray-6 rounded-corners p-3 status-bar">
            {isLoading ? (
              <div class="pb-2">
                <Loading class="small" />
              </div>
            ) : null}
            {contactcollector
              ? contactcollector.map((item, i) => (
                  <div key={i}>
                    <EmailStatus
                      marker={contactcollector.length - i - 1}
                      dryRun={false}
                      message={item.message}
                      recipient={item.recipient}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
