// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Scrollspy from "react-scrollspy"
import { connect } from "react-redux"
import { getCampaigns } from "../../actions/getCampaigns"
import Loading from "../loading"

import LandingImage from "../landingimage"

import parse from "url-parse"

import { FaChevronRight } from "react-icons/fa"

const mapStateToProps = ({ campaign, isLoading }) => {
  return { campaign, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getCampaigns: () => dispatch(getCampaigns()),
})

import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"
import { MdOndemandVideo, MdPhonelinkRing, MdShowChart } from "react-icons/md"

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    if (!this.props.campaign) {
      console.log("calling getCampaigns API *** campaign")
      this.props.getCampaigns()
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  imageSelector(filename, size) {
    if (filename) {
      let replaceFileType =
        filename.substr(0, filename.lastIndexOf(".")) + ".jpg"

      let imageSize =
        replaceFileType.substring(0, replaceFileType.lastIndexOf(".")) +
        `-${size}w` +
        replaceFileType.substring(replaceFileType.lastIndexOf("."))

      return imageSize
    } else {
      return filename
    }
  }

  listCampaigns(timeframe) {
    const { campaign } = this.props

    // console.log('moment.unix()')
    // console.log(moment().unix())
    // let now = moment().unix();
    // let challengeArr = this.state.challenge;

    // if(timeframe === 'current') {
    //   console.log('filtering current')
    //   // let test = challengeArr[0].dateend >= now;
    //   // console.log(test);
    //   challengeArr = challengeArr.filter((item) => {
    //     return item.dateend >= now;
    //   });
    // } else {
    //   console.log('filtering past')
    //   // let test = challengeArr[0].dateend < now;
    //   // console.log(test);

    //   challengeArr = challengeArr.filter((item) => {
    //     return item.dateend < now;
    //   });
    // }

    // console.log('challengeArr')
    // console.log(timeframe)
    // console.log(challengeArr)

    let campaignList = campaign
      ? campaign.map((item, i) => (
          <div
            class="card"
            key={`campaign-item-${i}`}
            style={{ minHeight: 150 }}
          >
            <Link to={`/app/campaign/videos/?t=${item.id}`}>
              {/* <LandingImage
                src={`https://s3.amazonaws.com/storylavaprime/template/${item.id}/${item.poster}`}
                // placeholder={`${bgthumb}`}
                objectPosition="center center"
                borderRadius={8}
              /> */}
              {/* <div
                class="app-challenge-image"
                style={{
                  minHeight: 150,
                  background: `url(https://s3.amazonaws.com/storylavaprime/template/${
                    item.id
                  }/${this.imageSelector(item.poster, "350")})`,
                  backgroundSize: "cover",
                  backgroundPositionY: "center",
                }}
              /> */}
              <div class="pathImageOverlay" />
              <div
                class="container-challenge-overview w-100"
                style={{ borderWidth: 1 }}
              >
                <div class="challenge-overview-title">
                  {item.admin && item.admin.title ? item.admin.title : ""}
                </div>
                {/* <div class="challenge-overview-dates">{item.fulldurationstring}</div> */}
                <div class="d-flex justify-content-end">
                  <FaChevronRight
                    color="#fff"
                    size={22}
                    style={{ opacity: 0.8 }}
                  />
                </div>
              </div>
            </Link>
          </div>
        ))
      : null

    return campaignList
  }

  render() {
    const { campaign, isLoading } = this.props
    console.log("this.state")
    console.log(this.state)

    return (
      <div class="reporting-container">
        {isLoading ? <Loading /> : null}
        <div class="card-columns">{this.listCampaigns()}</div>
        {/* <div class="row d-flex flex-column"></div> */}
        {/* <ul>
          {campaign
            ? campaign.map((item, i) => (
                <li>
                  <strong>{item.title ? item.title : ""}.&nbsp;</strong>
                </li>
              ))
            : null}
        </ul> */}
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
