import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"
import { Router } from "@reach/router"
// import Details from '../components/Details'
import AppHome from "../components/appHome"
// import AppTemplateOverview from "../components/appTemplateOverview"
import AppCampaignDetail from "../components/appCampaignDetail"
import AppActivate from "../components/appActivate"
// import AppTemplateDetail from "../components/appTemplateDetail"
// import AppChallengeOverview from '../components/appChallengeOverview'
// import AppChallengeDetail from '../components/appChallengeDetail'
// import Login from "../components/Login"
// import SignUp from '../components/SignUp'
import PrivateRoute from "../components/PrivateRoute"

const App = () => (
  <Router>
    {/* <PrivateRoute path="/app/home/" component={AppHome} />
    <PrivateRoute path="/app/collector/" component={AppHome} />
    <PrivateRoute path="/app/tracker/" component={AppHome} /> */}
    <PrivateRoute path="/app/composite/" component={AppHome} />
    <PrivateRoute path="/app/emailsend/" component={AppHome} />
    {/* <PrivateRoute path="/app/campaign/videos" component={AppCampaignDetail} />
    <PrivateRoute path="/app/campaign/users" component={AppCampaignDetail} />
    <PrivateRoute path="/app/campaign/social" component={AppCampaignDetail} />
    <PrivateRoute path="/app/reviews" component={AppCampaignDetail} />
    <PrivateRoute path="/app/moderation" component={AppCampaignDetail} />
    <PrivateRoute path="/app/activate" component={AppActivate} />
    <PrivateRoute path="/app/services-agreement" component={AppActivate} /> */}
    {/* <PrivateRoute path="/app/template/detail" component={AppTemplateDetail} /> */}
    {/* <PrivateRoute path="/app/profile" component={Details} />
      <PrivateRoute path="/app/challenge" component={AppChallengeOverview} />
      <PrivateRoute path="/app/challenge/detail" component={AppChallengeDetail} /> */}
    {/* <Login path="/app" /> */}
    {/* <SignUp path="/app/signup" /> */}
  </Router>
)

export default App
