import "babel-polyfill"
//import "isomorphic-fetch"
import React from "react"

// import AppNav from '../components/app/appNav'
// import Amplify, { API, Auth } from "aws-amplify"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import parse from "url-parse"

import { amplifyconfig } from "../utils/config"
import Layout from "../components/layout"
import NavApp from "../components/nav/navApp"

import ReportingMenu from "../components/appReporting/reportingMenu"
import CampaignsActivateDetail from "../components/appActivation/campaignsActivateDetail"
import AppServicesAgreement from "../components/appActivation/servicesAgreement"
import MenuBack from "../components/appReporting/menuBack"

import { Helmet } from "react-helmet"

import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

// eslint-disable-next-line
import "../css/app.css"

Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  render() {
    console.log("((this.state))")
    console.log(this.state)

    const url = parse(window.location.href, true)

    // console.log("campaign - url")
    // console.log(url)

    const pathname = url.pathname

    return (
      <Layout>
        <div class="flex-grow-1">
          {/* <AppNav logo={this.state.company && this.state.company.companylogo ? this.state.company.companylogo : ''} /> */}
          <NavApp />

          <div class="container container-app container-page container-reporting">
            <div class="row">
              {/* <div class="col-lg-3"><ReportingMenu /></div> */}
              {pathname === "/app/activate/" ? (
                <div class="col-lg-9">
                  <MenuBack text="Campaigns" link="/app/home/" />

                  <h3 class="pb-2">Program Details</h3>
                  <CampaignsActivateDetail />
                </div>
              ) : null}
              {pathname === "/app/services-agreement/" ? (
                <div class="col-lg-12">
                  <MenuBack text="Campaigns" link="/app/home/" />

                  {/* <h3 class="pb-2">Services Agreement</h3> */}
                  <AppServicesAgreement />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default App
