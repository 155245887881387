// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { navigate } from "@reach/router"

export function setEmailHtml(emailHtml) {
  return (dispatch) => {
    dispatch({
      type: "MAILMERGE_SETHTML_REPLACE",
      data: emailHtml,
    })
  }
}
export function setEmailRecipients(recipients) {
  return (dispatch) => {
    dispatch({
      type: "MAILMERGE_SETRECIPIENTS_REPLACE",
      data: recipients,
    })
  }
}
export function setMergeAux(data) {
  return (dispatch) => {
    dispatch({
      type: "MAILMERGE_AUX_REPLACE",
      data,
    })
  }
}

export function sendMail(
  mode,
  recipient,
  subject,
  bcc,
  content,
  dryrun,
  gettingstarted,
  overrideunsub,
  industry,
  recipientid
) {
  console.log("actions - sendMail - START **")

  let apiName = "Picovideoadmin"

  let path = "admin/notify/send"

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    let errorObj = { recipient: false, subject: false }

    if (mode === "solo" && (!recipient || !subject)) {
      if (!recipient) errorObj.recipient = true
      if (!subject) errorObj.subject = true
      dispatch({
        type: "SENDMAIL_ERROR",
        data: errorObj,
      })
      return
    } else if (mode === "signalsend" && !subject) {
      if (!subject) errorObj.subject = true
      dispatch({
        type: "SENDMAIL_ERROR",
        data: errorObj,
      })
      return
    } else {
      dispatch({
        type: "SENDMAIL_ERROR",
        data: errorObj,
      })
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("actions - sendMail - authuser - user")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          body: {
            // sender: user.attributes.email,
            recipient,
            subject,
            bcc,
            content,
            mode,
            dryrun,
            gettingstarted,
            overrideunsub,
            industry,
            userid: recipientid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - sendMail - post - data")
              console.log(data)

              return resolve(
                dispatch({
                  type: "SENDMAIL_REPLACE",
                  data: { message: mode, ...data },
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
