// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div class="report-header">{this.props.text}</div>
  }
}
export default Snippet
