// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  imageSelector(filename, size) {
    if (filename) {
      let replaceFileType =
        filename.substr(0, filename.lastIndexOf(".")) + ".jpg"

      let imageSize =
        replaceFileType.substring(0, replaceFileType.lastIndexOf(".")) +
        `-${size}w` +
        replaceFileType.substring(replaceFileType.lastIndexOf("."))

      return imageSize
    } else {
      return filename
    }
  }

  render() {
    return (
      <div
        class="campaign-header card mb-3"
        style={{ minHeight: 130, maxWidth: 400 }}
      >
        <div
          class="app-challenge-image"
          style={{
            minHeight: 130,
            backgroundImage: `url(https://s3.amazonaws.com/storylavaprime/template/${
              this.props.templateid
            }/${this.imageSelector(this.props.poster, "350")})`,
            backgroundSize: "cover",
            backgroundPositionY: "center",
          }}
        />
        <div class="pathImageOverlay" />
        <div
          class="container-challenge-overview w-100"
          style={{ borderWidth: 1 }}
        >
          <div class="challenge-overview-title">{this.props.title}</div>
          {/* <div class="challenge-overview-dates">{item.fulldurationstring}</div> */}
          {/* <div class="d-flex justify-content-end">
            <FaChevronRight color="#fff" size={22} style={{ opacity: 0.8 }} />
          </div> */}
        </div>
      </div>
    )
  }
}
export default Snippet
