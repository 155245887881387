import React from "react"
import { connect } from "react-redux"

import { MdMenu, MdExitToApp } from "react-icons/md"
import { Link } from "gatsby"

import { logoutUser } from "../../actions/getCampaigns"

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
})

class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div class="dropdown dropdown-app-menu">
        <button
          class="btn btn-app-menu"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <MdMenu size={23} />
        </button>
        <div
          class="dropdown-menu dropdown-menu-right black-3"
          aria-labelledby="dropdownMenuButton"
        >
          <button
            class="dropdown-item d-flex flex-row"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
            type="button"
            onClick={this.props.logoutUser}
          >
            <span>Sign out</span>
            <div style={{ marginLeft: "auto" }}>
              <MdExitToApp
                size={20}
                className="black-2"
                style={{ marginTop: -3 }}
              />
            </div>
          </button>
          {/* <Link class="dropdown-item d-flex flex-row" to="/">

          </Link> */}
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Nav)
