// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Scrollspy from "react-scrollspy"
import { connect } from "react-redux"
import { getAgreement } from "../../actions/getAgreement"
import Loading from "../loading"

import LandingImage from "../landingimage"
import moment from "moment"

import parse from "url-parse"

import { FaChevronRight } from "react-icons/fa"

import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"
import { MdOndemandVideo, MdPhonelinkRing, MdShowChart } from "react-icons/md"

const mapStateToProps = ({ agreements, isLoading }) => {
  return { agreements, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getAgreement: () => dispatch(getAgreement()),
})

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
      submitted: false,
    }
  }

  componentDidMount() {
    // if (!this.props.agreements) {
    this.props.getAgreement()
    // }
  }

  agreementStructure() {
    const { agreements } = this.props

    let agreementTextStructure = []

    agreements
      ? agreements.forEach(item => {
          if (item.type === "pageheader") {
            agreementTextStructure.push(<h2>{item.content}</h2>)
          } else if (item.type === "section") {
            agreementTextStructure.push(<h6>{item.content}</h6>)
          } else if (item.type === "term") {
            let innerText = []

            if (item.content.header) {
              innerText.push(<strong>{item.content.header} </strong>)
            }

            innerText.push(<span>{item.content.content}</span>)

            agreementTextStructure.push(<p>{innerText}</p>)
          } else if (item.type === "term-ol" || item.type === "term-ul") {
            let innerText = []

            item.content.forEach(subitem => {
              innerText.push(<li>{subitem}</li>)
            })

            if (item.type === "term-ol") {
              agreementTextStructure.push(<ol>{innerText}</ol>)
            }
            if (item.type === "term-ul") {
              agreementTextStructure.push(<ul>{innerText}</ul>)
            }
          }
        })
      : [] //end forEach

    return agreementTextStructure
  }

  render() {
    const { agreements, isLoading } = this.props

    console.log("[[[[agreements]]]]")
    console.log(agreements)

    return (
      <div class="reporting-container">
        {isLoading ? <Loading /> : null}

        <div class="container container-terms">{this.agreementStructure()}</div>
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
