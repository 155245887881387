// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { navigate } from "@reach/router"

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: "USER_LOGOUT",
    })

    Auth.signOut()
      .then((data) => {
        console.log("signed out *******")
        console.log(data)
        navigate("/")
      })
      .catch((err) => console.log(err))
  }
}
export function getCampaignsToActivate() {
  let cid = Cache.getItem("cid")

  console.log("cid&&&&&")
  console.log(cid)

  let apiName = "Picovideoweb"

  let path = "admin/campaigns/activate"

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get campaigns function---")
        console.log("[[[user]]]")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid: cid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("((data - campaign activate))")
              console.log(data)

              let result = data

              console.log("--campaign activate result PRE--++")
              console.log(result)
              result = result.filter((item) => !item.approved)

              console.log("--campaign activate result POST--++")
              console.log(result)
              return resolve(
                dispatch({
                  type: "CAMPAIGNS_TOACTIVATE_REPLACE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
