//React
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Auth } from "aws-amplify"

//Actions
// import { createTag, listTags, removeTag } from "../../actions/manageTags"
import { compositeImage } from "../../actions/compositeImage"

//Components
import Loading from "../loading"

//Icons
import {MdFileDownload } from "react-icons/md"

//Redux
const mapStateToProps = ({ imagecomposite, isLoading }) => {
  return { imagecomposite, isLoading }
}

const mapDispatchToProps = (dispatch) => ({
  // removeTag: (id) => dispatch(removeTag(id)),
  // listTags: () => dispatch(listTags()),
  // createTag: (content) => dispatch(createTag(content)),
  compositeImage: (baseimage,overlayimage) =>
    dispatch(compositeImage(baseimage,overlayimage)),
})

const Snippet = (props) => {
  const {
    imagecomposite,
    compositeImage,
    isLoading,
  } = props

  // const [userSub, setUserSub] = useState(null)

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      compositeImage(reader.result, 'static/promo/promo-new-template.png')
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }

  return (
    <div class="p-4">
        <div class="d-flex flex-row">
            <input id="upload" type="file" 
            onChange={(e)=> { 
                console.log('**e')
                console.log(e)
                getBase64(e.target.files[0])
            }}/>
            {isLoading? (<Loading class="small"/>): null}
        </div> 
        <div>
          {imagecomposite ? (<div class="d-flex flex-column"><a class="btn btn-primary my-3" download="image.jpg" href={imagecomposite}>Download <MdFileDownload size={16}/></a><img src={imagecomposite} width={400}/></div>):null}
        </div>
    </div>
  )
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
