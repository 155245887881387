import React from "react"
import { Link } from "gatsby"

import NavAppDropdownMenu from "./navAppDropdownMenu"
import NavSubHome from "./navSubHome"

import { GiMineWagon } from "react-icons/gi"
import "../../css/app.css"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <>
        <nav class="navbar navbar-sub navbar-expand-lg bg-blue-1">
          <div class="container">
            <div
              class="d-flex flex-row align-items-center justify-content-center flex-grow-1"
              id="navbarChallenge"
              style={{ minHeight: 30 }}
            >
              <div
                class="card-title-solo white-1 text-right pr-4"
                // style={{ paddingLeft: 10 }}
              >
                <div class="d-flex flex-row align-items-center">
                  {/* <div class="h4 yellow-1 mt-2 ml-1 mr-2">Prospector</div> */}
                  <GiMineWagon class="yellow-1" size={36} />
                </div>
                {/* {this.props.link === true ? (
                <Link
                  class="app-nav-description-link"
                  activeClassName="app-nav-description-link"
                  to="/app/home/"
                >
                  {this.props.title ? this.props.title : "Dashboard"}
                </Link>
              ) : (
                <div class="app-nav-description-link">
                  {this.props.title ? this.props.title : "Dashboard"}
                </div>
              )} */}
              </div>
              <ul class="d-flex flex-row align-items-center justify-content-center navbar-nav mr-auto">
                {/* <li class="nav-item pr-3">
                <Link
                  class="nav-link white-3"
                  activeClassName="nav-link white-2 active"
                  to="/app/home/"
                  style={{ marginTop: -2 }}
                >
                  <span>Campaigns</span>
                </Link>
              </li> */}
                {/* <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/about/agencies/"
                >
                  Reviews
                </Link>
              </li> */}
              </ul>
              <NavAppDropdownMenu />
            </div>
          </div>
        </nav>
        <NavSubHome />
      </>
    )
  }
}
