import React from "react"
import { Link } from "gatsby"

export default class Nav extends React.Component {
  render() {
    return (
      <nav class="navbar navbar-sub navbar-expand-lg bg-blue-5">
        <div class="container">
          <div
            class="d-flex flex-row align-items-center justify-content-center"
            id="navbarChallenge"
          >
            <ul class="d-flex flex-row align-items-center justify-content-center navbar-nav mr-auto">
              {/* <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/app/composite/"
                >
                  Composite
                </Link>
              </li> */}
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/app/emailsend/"
                >
                  Email send
                </Link>
              </li>
              {/* <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/app/home/"
                >
                  Mail
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/app/collector/"
                >
                  Collect
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/app/tracker/"
                >
                  Track
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
