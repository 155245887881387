// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import moment from "moment"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const minDateDay =
      this.props.bounds && this.props.bounds.min
        ? moment.unix(this.props.bounds.min / 1000).format("D")
        : ""
    const maxDateDay =
      this.props.bounds && this.props.bounds.max
        ? moment.unix(this.props.bounds.max / 1000).format("D")
        : ""
    const minDateMonth =
      this.props.bounds && this.props.bounds.min
        ? moment.unix(this.props.bounds.min / 1000).format("MM")
        : ""
    const maxDateMonth =
      this.props.bounds && this.props.bounds.max
        ? moment.unix(this.props.bounds.max / 1000).format("MM")
        : ""

    let dateRange
    if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth === maxDateMonth &&
      minDateDay === maxDateDay
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D, YYYY")}`
    } else if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth === maxDateMonth
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D")} - ${moment
        .unix(this.props.bounds.max / 1000)
        .format("D, YYYY")}`
    } else if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth !== maxDateMonth
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D")} - ${moment
        .unix(this.props.bounds.max / 1000)
        .format("MMM. D, YYYY")}`
    }

    // const minDate = this.props.bounds.min
    //   ? moment.unix(this.props.bounds.min / 1000).format("MM/DD/YYYY")
    //   : ""
    // const maxDate = this.props.bounds.max
    //   ? moment.unix(this.props.bounds.max / 1000).format("MM/DD/YYYY")
    //   : ""
    return (
      <div class="report-total-container d-flex flex-row align-items-center ">
        <div class="report-total-title d-flex flex-row justify-content-center align-items-center">
          {this.props.data ? this.props.data : 0}
        </div>
        <div class="report-total-label d-flex flex-row justify-content-start align-items-center ml-2">
          {this.props.data === 1 && this.props.singularText
            ? this.props.singularText
            : this.props.text}
        </div>
        <div class="report-total-date-range d-flex flex-grow-1 flex-column justify-content-center align-items-end text-right">
          {dateRange}
        </div>
      </div>
    )
  }
}
export default Snippet
