// import Amplify, { API, Auth } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
// import Cache from '@aws-amplify/cache';

import { groupBy } from "./groupBy"
import moment from "moment"

export function getSocialClicks(templateid) {
  let apiName = "Picovideoweb"

  let path = "admin/engage"

  function getMinTime(data) {
    return data.reduce((min, p) => (p.time < min ? p.time : min), data[0].time)
  }
  function getMaxTime(data) {
    return data.reduce((max, p) => (p.time > max ? p.time : max), data[0].time)
  }

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("actions - getSocialClicks - auth - user")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            t: templateid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              console.log("actions - getSocialClicks - then")

              const data = response || {}
              const socialResultContainer = {}

              if (data.length === 0) {
                socialResultContainer[templateid] = {
                  labels: [],
                  data: [],
                  total: 0,
                  bounds: {
                    max: 0,
                    min: 0,
                  },
                }

                dispatch({
                  type: "SOCIAL_GROUPED_REPLACE",
                  data: socialResultContainer,
                })
              }

              console.log("((data))")
              console.log(data)

              let results = data.map((item, i) => ({
                id: item.id,
                share: item.share,
                mode: item.mode,
                source: item.source,
                templateid: item.templateid,
                sessionid: item.sessionid,
                datecreated: item.datecreated,
              }))

              //GROUP RESULTS BY DATE FOR VIDEOS REPORTING PAGE
              // let groupedResults = lodashGroupBy(results, result =>
              //   moment
              //     .unix(result["datecreated"])
              //     // .format("YYYY-MM-DD")
              //     .startOf("day")
              // )

              let groupedResults = groupBy(
                results,
                (result) => moment.unix(result["datecreated"]).startOf("day")
                // .format("YYYY-MM-DD")
              )

              console.log("--groupedResults social2--++")
              console.log(groupedResults)

              let groupContainer = Object.keys(groupedResults).map(
                (key, index) => ({
                  time: parseInt(moment(key).format("x")),
                  email: {
                    facebook: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "facebook"
                    ).length,
                    twitter: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "twitter"
                    ).length,
                    instagram: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "instagram"
                    ).length,
                    sms: groupedResults[key].filter(
                      (item) => item.source === "email" && item.share === "sms"
                    ).length,
                    web: groupedResults[key].filter(
                      (item) => item.source === "email" && item.share === "web"
                    ).length,
                    tiktok: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "tiktok"
                    ).length,
                    snapchat: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "snapchat"
                    ).length,
                    download: groupedResults[key].filter(
                      (item) =>
                        item.source === "email" && item.share === "download"
                    ).length,
                    total: groupedResults[key].filter(
                      (item) => item.source === "email"
                    ).length,
                  },
                  landing: {
                    facebook: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "facebook"
                    ).length,
                    twitter: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "twitter"
                    ).length,
                    instagram: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "instagram"
                    ).length,
                    sms: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "sms"
                    ).length,
                    web: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "web"
                    ).length,
                    tiktok: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "tiktok"
                    ).length,
                    snapchat: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "snapchat"
                    ).length,
                    download: groupedResults[key].filter(
                      (item) =>
                        item.source === "landing" && item.share === "download"
                    ).length,
                    total: groupedResults[key].filter(
                      (item) => item.source === "landing"
                    ).length,
                  },
                  facebook: groupedResults[key].filter(
                    (item) => item.share === "facebook"
                  ).length,
                  twitter: groupedResults[key].filter(
                    (item) => item.share === "twitter"
                  ).length,
                  instagram: groupedResults[key].filter(
                    (item) => item.share === "instagram"
                  ).length,
                  sms: groupedResults[key].filter(
                    (item) => item.share === "sms"
                  ).length,
                  web: groupedResults[key].filter(
                    (item) => item.share === "web"
                  ).length,
                  tiktok: groupedResults[key].filter(
                    (item) => item.share === "tiktok"
                  ).length,
                  snapchat: groupedResults[key].filter(
                    (item) => item.share === "snapchat"
                  ).length,
                  download: groupedResults[key].filter(
                    (item) => item.share === "download"
                  ).length,
                  total: groupedResults[key].length,
                })
              )

              console.log("--groupContainer social--++")
              console.log(groupContainer)

              let max = getMaxTime(groupContainer)
              let min = getMinTime(groupContainer)

              console.log("max")
              console.log(max)
              console.log("min")
              console.log(min)

              //SEND MANUALLY PRODUCED TICKS
              let ticks = []
              let incrementValue = 86400000

              for (let i = min; i < parseInt(max + incrementValue); ) {
                ticks.push(i)
                // console.log("i")
                // console.log(i)
                i = i + incrementValue
              }

              // console.log("99ticks")
              // console.log(ticks)

              //PREPARE VIDEO REPORTING DATA
              socialResultContainer[templateid] = {
                labels: ticks,
                data: groupContainer,
                total: results.length,
                bounds: {
                  max: max,
                  min: min,
                },
              }
              // resultContainer[templateid] = groupContainer

              console.log("[[[[[[socialResultContainer]]]]]]")
              console.log(socialResultContainer)
              /// WORK ON USERS NOW

              return resolve(
                dispatch({
                  type: "SOCIAL_GROUPED_REPLACE",
                  data: socialResultContainer,
                })
              )
            })
            .catch((error) => {
              console.log("actions - getSocialClicks - catch")
              console.log(error)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
