import React, { Component } from "react"

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageStatus: "initial",
    }
  }
  handleImageLoaded() {
    this.setState({ imageStatus: "loaded" })
  }

  render() {
    // console.log("this.props")
    // console.log(this.props)

    let image350
    let image700
    let image1400

    let imagePath = this.props && this.props.src ? this.props.src : ""
    let replaceFileType =
      imagePath.substr(0, imagePath.lastIndexOf(".")) + ".jpg"

    image350 =
      replaceFileType.substring(0, replaceFileType.lastIndexOf(".")) +
      "-350w" +
      replaceFileType.substring(replaceFileType.lastIndexOf("."))
    image700 =
      replaceFileType.substring(0, replaceFileType.lastIndexOf(".")) +
      "-700w" +
      replaceFileType.substring(replaceFileType.lastIndexOf("."))
    image1400 =
      replaceFileType.substring(0, replaceFileType.lastIndexOf(".")) +
      "-1400w" +
      replaceFileType.substring(replaceFileType.lastIndexOf("."))

    return this.props && this.props.src ? (
      <div
        className="image-wrapper"
        style={{
          position: "absolute",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          top: "0px",
        }}
      >
        {/* <img
          src={this.props.placeholder}
          alt=""
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: this.props.objectPosition,
            opacity: this.state.imageStatus === "loaded" ? 0 : 1,
            filter: "blur(20px)",
            transition:
              this.state.imageStatus === "loaded" ? "ease 2s" : "none",
          }}
        /> */}
        <picture>
          <img
            onLoad={this.handleImageLoaded.bind(this)}
            sizes="(max-width: 1400px) 100vw, 1400px"
            srcSet={`${image350} 350w,
                ${image700} 700w,
                ${image1400} 1400w`}
            src={this.props && this.props.src ? this.props.src : ""}
            alt=""
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: this.props.objectPosition
                ? this.props.objectPosition
                : "center center",
              opacity: this.state.imageStatus === "loaded" ? 1 : 0,
              transition:
                this.state.imageStatus === "loaded" ? "ease 1s" : "none",
              backfaceVisibility: "hidden",
              transform: "translateZ(0)",
              perspective: 1000,
              transformStyle: "preserve-3d",
              borderRadius: this.props.borderRadius
                ? this.props.borderRadius
                : 0,
            }}
          />
        </picture>

        <noscript>
          <picture>
            <img
              sizes="(max-width: 1400px) 100vw, 1400px"
              srcSet={`${image350} 350w,
                ${image700} 700w,
                ${image1400} 1400w`}
              src={this.props && this.props.src ? this.props.src : ""}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 1,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: this.props.objectPosition
                  ? this.props.objectPosition
                  : "center center",
                borderRadius: this.props.borderRadius
                  ? this.props.borderRadius
                  : 0,
              }}
            />
          </picture>
        </noscript>
      </div>
    ) : null
  }
}

export default Image
