// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Scrollspy from "react-scrollspy"
import { connect } from "react-redux"
import { getCampaignsToActivate } from "../../actions/getCampaignsToActivate"
import { activateCampaign } from "../../actions/activateCampaign"

import Loading from "../loading"

import LandingImage from "../landingimage"
import moment from "moment"

import parse from "url-parse"

import { FaChevronRight, FaCheck, FaSpinner } from "react-icons/fa"

const mapStateToProps = ({
  campaignactivate,
  isLoading,
  loadingSubmission,
  submitted,
}) => {
  return { campaignactivate, isLoading, loadingSubmission, submitted }
}

const mapDispatchToProps = (dispatch) => ({
  getCampaignsToActivate: () => dispatch(getCampaignsToActivate()),
  activateCampaign: (id, title) => dispatch(activateCampaign(id, title)),
})

import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"
import { MdOndemandVideo, MdPhonelinkRing, MdShowChart } from "react-icons/md"

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
      submitted: false,
      loadingSubmission: false,
    }
  }
  componentDidMount() {
    console.log("___mounting campaignsActivateDetail___")
    console.log(this.props)

    if (!this.props.campaignactivate) {
      console.log("calling getCampaignsToActivate API *** campaign")
      this.props.getCampaignsToActivate()
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  render() {
    const { campaignactivate, isLoading, loadingSubmission, submitted } =
      this.props
    console.log("campaignactivate")
    console.log(campaignactivate)

    const url = parse(window.location.href, true)

    const orderDetail = campaignactivate
      ? campaignactivate.find(({ id }) => id === url.query.o)
      : {}

    return (
      <div class="reporting-container">
        {/* {isLoading ? <Loading /> : null} */}

        {!isLoading ? (
          <div class="container container-app container-page">
            {/* <div class="row">
              <div class="col-md-6 d-flex flex-column align-items-start justify-content-start">
                <h4 class="mb-4">Program Details</h4>
              </div>
            </div> */}

            <div class="row">
              <div class="col-md-12">
                <h5 style={{ marginBottom: 20 }}>
                  {orderDetail && orderDetail.title ? orderDetail.title : ""}
                </h5>

                <div class="details-items" style={{ paddingTop: 15 }}>
                  {orderDetail && orderDetail.orderitems
                    ? orderDetail.orderitems.map((item) => (
                        <div>
                          <div
                            class="bg-blue-1 p-2"
                            style={{
                              borderTopRightRadius: 6,
                              borderTopLeftRadius: 6,
                            }}
                          >
                            <strong class="white-1">{item.title}</strong>
                          </div>
                          <table class="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td width="80">
                                  <i>Start</i>
                                </td>
                                <td>
                                  {moment
                                    .unix(item.startdate)
                                    .format("MMMM D, YYYY")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <i>End</i>
                                </td>
                                <td>
                                  {moment
                                    .unix(item.enddate)
                                    .format("MMMM D, YYYY")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p
                            class="black-3"
                            style={{ fontSize: 14, lineHeight: 1 }}
                          >
                            {item && item.comments ? item.comments : ""}
                          </p>
                        </div>
                      ))
                    : null}
                </div>

                <div class="details-additional" style={{ paddingTop: 10 }}>
                  <table
                    class="table  table-bordered black-3"
                    style={{ fontSize: 14, lineHeight: 1 }}
                  >
                    <tbody>
                      {orderDetail &&
                      orderDetail.orderdetail &&
                      orderDetail.orderdetail.platformprice >= 0 ? (
                        <>
                          <tr class="bg-black-5">
                            <td>
                              <strong>Platform</strong>
                            </td>
                            <td width="25%">
                              <strong>Price</strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>
                                <strong>Base platform fee</strong>
                              </p>
                              <ul>
                                <li>
                                  Guided (chatbot driven) user experience for
                                  acquiring user content
                                </li>
                                <li>
                                  Supports iOS, Android, Windows and popular
                                  browsers, with rapid user on-boarding (no app
                                  install)
                                </li>
                                <li>
                                  {orderDetail.orderdetail.tier ? (
                                    <>
                                      Video volume tier
                                      <ul>
                                        <li>
                                          Tier {orderDetail.orderdetail.tier}
                                        </li>
                                        <li>
                                          Includes SAAS hosting and video
                                          rendering
                                        </li>
                                      </ul>
                                    </>
                                  ) : null}
                                </li>
                              </ul>

                              {orderDetail.orderdetail.platformcomments ? (
                                <p style={{ marginLeft: 20 }}>
                                  {orderDetail.orderdetail.platformcomments}
                                </p>
                              ) : null}
                            </td>
                            <td>${orderDetail.orderdetail.platformprice}</td>
                          </tr>
                        </>
                      ) : null}
                      {orderDetail &&
                      orderDetail.orderdetail &&
                      orderDetail.orderdetail.socialprice >= 0 ? (
                        <tr>
                          <td>
                            <p>
                              <strong>Social Sharing.</strong> Enable rapid,
                              easy sharing of story videos on users’ social
                              networks.
                            </p>
                            <ul>
                              <li>
                                Enhanced notification email. HTML email to users
                                on completion of video with link to social
                                sharing landing page
                              </li>
                            </ul>
                            {orderDetail.orderdetail.socialcomments ? (
                              <p style={{ marginLeft: 20 }}>
                                {orderDetail.orderdetail.socialcomments}
                              </p>
                            ) : null}
                          </td>
                          <td>${orderDetail.orderdetail.socialprice}</td>
                        </tr>
                      ) : null}
                      {orderDetail &&
                      orderDetail.orderdetail &&
                      orderDetail.orderdetail.leadgenprice >= 0 ? (
                        <tr>
                          <td>
                            <p>
                              <strong>Lead generation.</strong> Capture contact
                              info generated from program for use in follow-on
                              nurture marketing campaigns
                            </p>
                            <ul>
                              <li>
                                {orderDetail.orderdetail.leadgenmode === "email"
                                  ? "Email"
                                  : null}
                                {orderDetail.orderdetail.leadgenmode === "sms"
                                  ? "SMS"
                                  : null}{" "}
                                video delivery
                              </li>
                              <li>
                                Includes{" "}
                                {orderDetail.orderdetail.leadgenmode === "email"
                                  ? "email address"
                                  : null}
                                {orderDetail.orderdetail.leadgenmode === "sms"
                                  ? "mobile phone number"
                                  : null}{" "}
                                validation
                              </li>
                            </ul>
                            {orderDetail.orderdetail.leadgencomments ? (
                              <p style={{ marginLeft: 20 }}>
                                {orderDetail.orderdetail.leadgencomments}
                              </p>
                            ) : null}
                          </td>
                          <td>${orderDetail.orderdetail.leadgenprice}</td>
                        </tr>
                      ) : null}
                      {orderDetail &&
                      orderDetail.orderdetail &&
                      orderDetail.orderdetail.moderationprice >= 0 ? (
                        <tr>
                          <td>
                            <p>
                              <strong>Content moderation.</strong> Screen
                              content for brand-safe imagery and text
                            </p>
                            <ul>
                              <li>
                                {orderDetail.orderdetail.moderationmode ===
                                "manual"
                                  ? "Human-based manual content screening"
                                  : null}
                              </li>
                            </ul>
                            {orderDetail.orderdetail.moderationcomments ? (
                              <p style={{ marginLeft: 20 }}>
                                {orderDetail.orderdetail.moderationcomments}
                              </p>
                            ) : null}
                          </td>
                          <td>${orderDetail.orderdetail.moderationprice}</td>
                        </tr>
                      ) : null}

                      <tr>
                        <td class="bg-black-5 text-right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>
                            $
                            {(orderDetail.orderdetail &&
                            orderDetail.orderdetail.platformprice
                              ? orderDetail.orderdetail.platformprice
                              : 0) +
                              (orderDetail.orderdetail &&
                              orderDetail.orderdetail.leadgenprice
                                ? orderDetail.orderdetail.leadgenprice
                                : 0) +
                              (orderDetail.orderdetail &&
                              orderDetail.orderdetail.socialprice
                                ? orderDetail.orderdetail.socialprice
                                : 0) +
                              (orderDetail.orderdetail &&
                              orderDetail.orderdetail.moderationprice
                                ? orderDetail.orderdetail.moderationprice
                                : 0)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr size="1" />

                  {orderDetail && orderDetail.comments
                    ? orderDetail.comments.map((item) => (
                        <p
                          class="black-3"
                          style={{ fontSize: 14, lineHeight: 1 }}
                        >
                          <strong>
                            {item && item.title ? item.title : ""}
                            <br />
                          </strong>
                          {item && item.content ? item.content : ""}
                        </p>
                      ))
                    : null}

                  <hr size="1" />

                  <p class="black-3" style={{ fontSize: 14, lineHeight: 1 }}>
                    Picovideo services are subject to individual user acceptance
                    of the Picovideo{" "}
                    <a href="https://storylava.com/about/terms" target="_blank">
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://storylava.com/about/privacy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    , and to your acceptance of the Picovideo{" "}
                    <a href="/app/services-agreement/" target="_blank">
                      Services Agreement
                    </a>
                    . By clicking "Activate" below, you agree to be bound by the
                    terms of the Services Agreement, and that these Program
                    Details are incorporated into that Services Agreement.
                  </p>
                </div>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-12">
                <hr size="1" color="#307adb" />
              </div>
            </div>

            {/* BEGIN ROW */}
            {orderDetail && orderDetail.approved ? (
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-row align-items-center justify-content-center pl-4">
                    <FaCheck
                      color="#00ae42"
                      size={30}
                      style={{ marginLeft: 10 }}
                    />
                    <div
                      class="black-3 "
                      style={{
                        fontSize: 15,
                        lineHeight: "15px",
                        paddingLeft: 10,
                      }}
                    >
                      This program was activated by{" "}
                      {orderDetail && orderDetail.approvedby
                        ? orderDetail.approvedby
                        : ""}{" "}
                      on{" "}
                      {orderDetail && orderDetail.approveddate
                        ? moment(orderDetail.approveddate).format(
                            "MMMM D, YYYY, h:mm:ss"
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="row">
                {submitted === false ? (
                  <div class="col-md-6">
                    <button
                      class="btn btn-lg btn-primary"
                      style={{ minWidth: 200 }}
                      onClick={() =>
                        this.props.activateCampaign(
                          url.query.o,
                          orderDetail && orderDetail.title
                            ? orderDetail.title
                            : "Campaign"
                        )
                      }
                    >
                      Activate
                      {loadingSubmission === true ? (
                        <FaSpinner
                          size={15}
                          className="icon-spin"
                          style={{ marginLeft: "10px" }}
                        />
                      ) : null}
                    </button>
                  </div>
                ) : null}

                <div class="col-md-6 d-flex flex-row align-items-center justify-content-center">
                  {submitted === true ? (
                    <div class="d-flex flex-row align-items-center justify-content-center pl-4">
                      <FaCheck
                        color="#00ae42"
                        size={30}
                        style={{ marginLeft: 10 }}
                      />
                      <div
                        class="black-3 "
                        style={{
                          fontSize: 15,
                          lineHeight: "15px",
                          paddingLeft: 10,
                        }}
                      >
                        You've activated your campaign and can view it on the{" "}
                        <Link to="/app/home">Campaigns</Link> dashboard.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {/* END ROW */}
          </div>
        ) : (
          <div
            class="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: 500 }}
          >
            <Loading class="small" />
          </div>
        )}
      </div>
    )
  }
}

Snippet.defaultProps = {
  submitted: false,
}
// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
