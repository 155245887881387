// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import Scrollspy from "react-scrollspy"

import parse from "url-parse"

import { Link } from "gatsby"

import { FaCommentDots, FaRobot, FaShare } from "react-icons/fa"
import { MdOndemandVideo, MdPhonelinkRing, MdShowChart } from "react-icons/md"

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    this.setState({ scrolled: "chatbot" })
  }
  checkAccess(menuitem) {
    //see if access value is in campaignDetail
    const { campaignDetail } = this.props

    if (!menuitem.access) {
      return true
    } else if (
      menuitem.access.some(accessitem => {
        if (accessitem.templateparam.includes("/")) {
          const splitString = accessitem.templateparam.split("/")
          console.log("{{splitString}}")
          console.log(splitString)

          if (
            Object.entries(campaignDetail).length !== 0 &&
            campaignDetail.constructor === Object
          ) {
            //check if empty
            console.log("campaignDetail")
            console.log(campaignDetail)
            let nestedObject = campaignDetail
            splitString.forEach(item => {
              nestedObject = nestedObject[item]
                ? nestedObject[item]
                : nestedObject
            })
            console.log("[[[[[[nestedObject]]]]]]")
            console.log(nestedObject)

            return accessitem.templatevalue === nestedObject
          } else {
            return false
          }
        } else {
          return (
            accessitem.templatevalue ===
            campaignDetail[accessitem.templateparam]
          )
        }
      })
    ) {
      return true
    }
    return false
  }
  render() {
    const url = parse(window.location.href, true)

    console.log("menu === - url")
    console.log(url)
    console.log("this.props.menudata")
    console.log(this.props.menudata)
    console.log("[[[[[this.props.campaignDetail]]]]]")
    console.log(this.props.campaignDetail)

    return (
      <div class="feature-menu-container">
        {this.props.menudata.map((item, i) => (
          <div>
            {this.checkAccess(item) ? (
              <div class="feature-menu-subcontainer">
                <div class="feature-menu">
                  <div class="feature-menu-header card-title-solo bg-blue-1 white-1">
                    {item.header}
                  </div>

                  {item.items.map((subitem, j) => (
                    <>
                      {this.checkAccess(subitem) ? (
                        <li
                          class={
                            url.pathname === subitem.url ? "menu-active" : ""
                          }
                        >
                          <Link
                            to={`${subitem.url}?t=${this.props.templateid}`}
                            class="feature-menu-link scroll"
                          >
                            <span class="black-3">{subitem.title}</span>
                          </Link>
                        </li>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    )
  }
}

export default Snippet
